import React, { useEffect, useRef, useState } from "react"
import { StyledButton } from "../../Styled/Button"

import ReCAPTCHA from "react-google-recaptcha"
import { withCustomForm } from "./withEmarsysForm"
import { Section } from "./EmarsysFormStyles"
import { FlexibleWrapper } from "../../Contact/Form/FormStyles"
import { RichText } from "../../RichText/RichText"

export const CustomHtml = withCustomForm(({errors, description, image, form, handleSubmit, isBrowser, recaptchaRef}) => 
<>
<Section>
<form method="get" action="https://link.rbsellars.com.au/u/register.php" onSubmit={handleSubmit}>
  {(form.map((wrapper, index) => {
    const Wrapper = wrapper.wrapper  
    return (
    <>
    { index === 0 && (
      <FlexibleWrapper className="pb-4">
        <div>
          <img className="w-full pb-3" src={image.asset.url} alt={image.alt} />
          <div className="rte">
            <RichText>{description}</RichText>
          </div>
        </div>
      </FlexibleWrapper>
    )}
    <div dangerouslySetInnerHTML={{ __html: wrapper.hiddenFields }} />
    <Wrapper key={`wrapper-${index}`}>
        { wrapper.fields.map((field, fi) => {
        const Field = field.component  
        return <Field key={`field-${index}-${fi}`} type={field.props.type} {...field.props}></Field>
      })}
    </Wrapper>
    </>)}))}
    <br />

    {isBrowser && <ReCAPTCHA className="mb-2" ref={recaptchaRef} sitekey="6Lcd1rwUAAAAAKXmI-bwFA-2D5RNff4FV6InYoTK" />}
    <StyledButton

          type="submit"
          size={"primary"}      
        >
          Submit
        </StyledButton>

        { errors.map(e => (<div className="text-red mt-2" key={e}>{e}</div>))}
</form> 
</Section></>)
export default CustomHtml